import React, { FunctionComponent, useState, useEffect } from 'react';
import { StyledButton, StyledButtonProps } from '../styled-button/styled-button.component';

const TITLE = 'Enable JavaScript in your browser settings to use this form';
export type SubmitButtonProps = Omit<StyledButtonProps, 'buttonType' | 'title'>;

/**
 * A StyledButton for use as a submit button on forms.  This button will be disabled if
 * JavaScript is not enabled in the browser.  This can be useful to prevent form submits
 * when JS is disabled which can lead to sensitive fields being serialized in the URL.
 */
export const SubmitButton: FunctionComponent<SubmitButtonProps> = ({ disabled, ...remainingProps }) => {
	const [submitDisabled, setSubmitDisabled] = useState(true);
	useEffect(() => setSubmitDisabled(false), []);
	return (
		<StyledButton
			disabled={submitDisabled || disabled}
			buttonType="submit"
			testId="submit"
			title={submitDisabled ? TITLE : undefined}
			{...remainingProps}
		/>
	);
};
